<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="preferenceWidgetSearchQuery"
			search-query-label="Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changePreferenceWidgetSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataKey="changeMetaDataKey"
			@persistMetaDataValueSearchQuery="changeMetaDataValueSearchQuery"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="createNewPreferenceWidget"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Embedded Consent Collection
			</template>
			<template #body>
				<DataTable
					:items="filteredPreferenceWidgets"
					:headers="tableHeaders"
					sort-by="preferenceWidgetId"
					sort-desc
					@click:row="editPreferenceWidget"
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Edit Preference Widget"
							@click="editPreferenceWidget(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Preference Widget"
							@click="editPreferenceWidget(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							tooltip-text="View Embed Code"
							@click.stop.prevent="onViewPreferenceWidget(item)"
						>
							mdi-link
						</IconButton>
						<IconButton
							v-if="userFullPermissions && userHasBrand(item.brandId)"
							tooltip-text="Delete Preference Widget"
							@click.stop.prevent="$emit('delete-preference-widget', item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { changePreferenceWidgetSearchQuery, preferenceWidgetSearchQuery } from '../../../../../shared/state/preference-widgets.js'
import {
	changeMetaDataValueSearchQuery,
	changeMetaDataKey,
	metaDataValueSearchQuery,
	selectedMetaDataKey
} from '../../../../../shared/state/meta-data.js'
import { MANAGE_PREFERENCE_WIDGET } from '../../../router/route-names.js'
import { brandOptions, changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
export default {
	components: {
		DataTable,
		SectionCard,
		PrimaryActionButton,
		FilterBar,
		IconButton
	},
	props: {
		preferenceWidgets: {
			type: Array,
			default: () => []
		},
		tableHeaders: {
			type: Array,
			default: () => []
		},
		lookupData: {
			type: Object,
			default: () => {}
		},
		userFullPermissions: Boolean,
		userReadOnly: Boolean
	},
	setup () {
		return {
			brandOptions,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changePreferenceWidgetSearchQuery,
			preferenceWidgetSearchQuery,
			changeMetaDataValueSearchQuery,
			changeMetaDataKey,
			metaDataValueSearchQuery,
			selectedMetaDataKey
		}
	},
	computed: {
		filteredPreferenceWidgets () {
			return this.preferenceWidgets.filter(({ preferenceWidgetName, brandId, externalValues }) => {
				let check = true
				if (preferenceWidgetSearchQuery.value) check = preferenceWidgetName?.toLowerCase().includes(preferenceWidgetSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedMetaDataKey.value !== null) check = check && externalValues?.some(metadata => metadata.metaDataKeyId === selectedMetaDataKey.value)
				if (metaDataValueSearchQuery.value) {
					check = check && externalValues?.some(metadata =>
						metadata.metaDataValue.toLowerCase().includes(metaDataValueSearchQuery.value.toLowerCase()))
				}
				return check
			})
		}
	},
	methods: {
		userHasBrand (brandId) {
			return brandOptions.value?.find(({ value }) => value === brandId)
		},
		createNewPreferenceWidget () {
			const lookupData = this.lookupData
			const userFullPermissions = this.userFullPermissions
			this.$router.push({ name: MANAGE_PREFERENCE_WIDGET, params: { lookupData, userFullPermissions } })
		},
		editPreferenceWidget (preferenceWidgetToEdit) {
			const lookupData = this.lookupData
			const userFullPermissions = this.userFullPermissions
			this.$router.push({ name: MANAGE_PREFERENCE_WIDGET, params: { preferenceWidgetToEdit, lookupData, userFullPermissions } })
		},
		onViewPreferenceWidget (preferenceWidgetToEdit) {
			const lookupData = this.lookupData
			const userFullPermissions = this.userFullPermissions
			this.$router.push({
				name: MANAGE_PREFERENCE_WIDGET,
				params: {
					preferenceWidgetToEdit,
					lookupData,
					initialStep: 2,
					userFullPermissions
				}
			})
		}
	}
}
</script>
