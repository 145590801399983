<template>
	<PreferenceWidgetsLayout>
		<template #content>
			<PreferenceWidgetsTab
				:preference-widgets="preferenceWidgets"
				:table-headers="preferenceWidgetsTableHeaders"
				:user-full-permissions="userFullPermissions"
				:user-read-only="userReadOnly"
				@delete-preference-widget="setDeletedItem"
			/>
			<ConfirmDeleteModal
				v-if="preferenceWidgetToBeRemoved"
				:entity-name="preferenceWidgetToBeRemoved.preferenceWidgetName"
				entity-type="Preference Widget"
				@close="preferenceWidgetToBeRemoved = null"
				@delete="deletePreferenceWidget"
			/>
		</template>
	</PreferenceWidgetsLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
	deletePreferenceWidget,
	getPreferenceWidgets
} from '../../../../../shared/utils/api/preference-widgets.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import PreferenceWidgetsLayout from './preference-widgets-layout.vue'
import { MANAGE_PREFERENCE_WIDGET } from '../../../router/route-names.js'
import PreferenceWidgetsTab from './preference-widgets-tab.vue'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { PREF_WIDGETS_AREA_FULL_PERMISSIONS, SEE_PREF_WIDGETS_AREA } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		PreferenceWidgetsLayout,
		ConfirmDeleteModal,
		PreferenceWidgetsTab
	},
	data () {
		return {
			preferenceWidgets: [],
			preferenceWidgetToBeRemoved: null,
			languages: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		...mapGetters('lookupData', ['preferenceWidgetsLookupData']),
		preferenceWidgetsTableHeaders () {
			return [
				{ value: 'preferenceWidgetId', text: 'ID', width: '10%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '30%' },
				{ value: 'preferenceWidgetName', text: 'Collection Name', width: '30%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		multipleLanguagesEnabled () {
			return this.languages.length > 0
		},
		userFullPermissions () {
			return this.productAreaPermission(PREF_WIDGETS_AREA_FULL_PERMISSIONS)
		},
		userReadOnly () {
			return this.productAreaPermission(SEE_PREF_WIDGETS_AREA)
		}
	},
	async created () {
		this.loadPreferenceWidgets()
		const { data: assignedLanguages } = await getAssignedLanguages()
		this.languages = assignedLanguages
	},
	methods: {
		...mapActions('lookupData', ['storePreferenceWidgetLookupData']),
		async loadPreferenceWidgets () {
			const { preferenceWidgets, lookupData } = await getPreferenceWidgets()
			this.preferenceWidgets = preferenceWidgets
			if (!this.preferenceWidgetsLookupData) {
				this.storePreferenceWidgetLookupData(lookupData)
			}
		},
		labelsTranslated (labelTranslation) {
			return Boolean(labelTranslation.optInLabelTranslation || labelTranslation.optOutLabelTranslation || labelTranslation.noChangeLabelTranslation)
		},
		createNewPreferenceWidget () {
			this.$router.push({ name: MANAGE_PREFERENCE_WIDGET })
		},
		editPreferenceWidget (preferenceWidgetToEdit) {
			this.$router.push({ name: MANAGE_PREFERENCE_WIDGET, params: { preferenceWidgetToEdit } })
		},
		onViewPreferenceWidget (preferenceWidgetToEdit) {
			const lookupData = this.lookupData
			this.$router.push({
				name: MANAGE_PREFERENCE_WIDGET,
				params: {
					preferenceWidgetToEdit,
					lookupData,
					initialStep: 1
				}
			})
		},
		setDeletedItem (preferenceWidget) {
			this.preferenceWidgetToBeRemoved = preferenceWidget
		},
		async deletePreferenceWidget () {
			await deletePreferenceWidget(this.preferenceWidgetToBeRemoved.preferenceWidgetId)
			await this.loadPreferenceWidgets()
			showSnackbar('This preference widget has been removed')
			this.preferenceWidgetToBeRemoved = null
		}
	}
}
</script>
