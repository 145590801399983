<template>
	<ViewLayout>
		<template #header-title>
			Embedded Consent Collection
		</template>
		<template #header-caption>
			Add, edit and manage embedded consent collection
		</template>
		<template #header-after>
			<slot name="header-after" />
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
