import { format } from 'date-fns'
import { DATE_FORMAT } from '../../utils/date-formatting.js'
import { configApi } from '../http-client.js'

export const getPreferenceWidgets = () => configApi.get('/api/PreferenceWidgets').then(({ data }) => ({
	preferenceWidgets: data.preferenceWidgets.map(prefWidget => ({
		createdDate: format(new Date(prefWidget.createdDate), DATE_FORMAT),
		preferenceWidgetId: prefWidget.preferenceWidgetId,
		preferenceWidgetName: prefWidget.preferenceWidgetName,
		brandId: prefWidget.brandID,
		brandName: prefWidget.brandName,
		preferenceWidgetDescription: prefWidget.prefWidgetDescription,
		headerId: prefWidget.appearanceData.headerId,
		footerId: prefWidget.appearanceData.footerId,
		confirmationPageId: prefWidget.confirmationData.confirmationPageId,
		confirmationEmailId: prefWidget.confirmationData.confirmationEmailId,
		optionAppearance: getOptionAppearance(
			prefWidget.appearanceData.optionsData.optInLabel,
			prefWidget.appearanceData.optionsData.optOutLabel,
			prefWidget.appearanceData.optionsData.hideNoChangeOption
		),
		optInLabel: prefWidget.appearanceData.optionsData.optInLabel,
		optOutLabel: prefWidget.appearanceData.optionsData.optOutLabel,
		noChangeLabel: prefWidget.appearanceData.optionsData.noChangeLabel,
		labelLocation: prefWidget.appearanceData.optionsData.optionsLabelsPositionId === 1,
		optionOrder: prefWidget.appearanceData.optionsData.optionsOrderId === 1,
		defaultOption: prefWidget.appearanceData.optionsData.optionSelectedByDefaultId,
		allowedDomains: prefWidget.allowedDomains ? prefWidget.allowedDomains.split(',') : null,
		extendedPreferences: prefWidget.preferenceData.extendedPreferences,
		statementGroups: prefWidget.preferenceData.statementGroups.map(statementGroup => ({
			statementGroupID: statementGroup.statementGroupId,
			statementGroupName: statementGroup.statementGroupName,
			displayOrder: statementGroup.displayOrder,
			channelId: statementGroup.channelId,
			channelName: statementGroup.channelName,
			brandId: statementGroup.brandId,
			brandName: statementGroup.brandName,
			statements: statementGroup.statements,
			enforceDoubleOptIn: statementGroup.enforceDoubleOptIn
		})),
		externalValues: prefWidget.preferenceData.metaData.map(metadata => ({
			metaDataKeyId: metadata.keyId,
			metaDataKeyName: metadata.keyName,
			metaDataValue: metadata.value
		})),
		customPrivacyPolicies: prefWidget.customPrivacyPolicies.map(privacyPolicy => ({
			...privacyPolicy,
			privacyPolicyVersionId: privacyPolicy.privacyPolicyVersionId ? privacyPolicy.privacyPolicyVersionId : 'Always Use Latest Version',
			privacyPolicyExternalVersionRef: privacyPolicy.externalVersionRef
		}))
	})),
	lookupData: data.lookupData
}))

function getOptionAppearance (optInLabel, optOutLabel, hideNoChangeOption) {
	const CHECKBOX_APPEARANCE = 1
	const RADIO_IN_OUT_APPEARANCE = 2
	const RADIO_IN_NOCHANGE_OUT_APPEARANCE = 3

	if (optInLabel && optOutLabel && !hideNoChangeOption) {
		return RADIO_IN_NOCHANGE_OUT_APPEARANCE
	} else if (optInLabel && optOutLabel && hideNoChangeOption) {
		return RADIO_IN_OUT_APPEARANCE
	} else {
		return CHECKBOX_APPEARANCE
	}
}

export const createPreferenceWidget = newPreferenceWidget => {
	const CHECKBOX_APPEARANCE = 1
	const RADIO_APPEARANCE = 2
	if (newPreferenceWidget.optionAppearance === CHECKBOX_APPEARANCE) {
		newPreferenceWidget.noChangeLabel = null
		newPreferenceWidget.optInLabel = null
		newPreferenceWidget.optOutLabel = null
		newPreferenceWidget.labelLocation = false
		newPreferenceWidget.optionOrder = false
		newPreferenceWidget.defaultOption = null
	} else if (newPreferenceWidget.optionAppearance === RADIO_APPEARANCE) {
		newPreferenceWidget.noChangeLabel = null
	}

	if (newPreferenceWidget.headerId === null) {
		delete newPreferenceWidget.headerId
	}
	if (newPreferenceWidget.footerId === null) {
		delete newPreferenceWidget.footerId
	}

	const prefWidget = {
		prefWidgetName: newPreferenceWidget.preferenceWidgetName,
		prefWidgetDescription: newPreferenceWidget.preferenceWidgetDescription,
		brandId: newPreferenceWidget.brandId,
		allowedDomains: newPreferenceWidget.allowedDomains ? newPreferenceWidget.allowedDomains.join(',') : null,
		confirmationData: {
			confirmationPageId: newPreferenceWidget.confirmationPageId,
			confirmationEmailId: newPreferenceWidget.confirmationEmailId
		},
		appearanceData: {
			headerId: newPreferenceWidget.headerId,
			footerId: newPreferenceWidget.footerId,
			optionsData: {
				optInLabel: newPreferenceWidget.optInLabel,
				optOutLabel: newPreferenceWidget.optOutLabel,
				noChangeLabel: newPreferenceWidget.noChangeLabel,
				optionsLabelsPositionId: newPreferenceWidget.labelLocation,
				optionsOrderId: newPreferenceWidget.optionOrder,
				optionSelectedByDefaultId: newPreferenceWidget.defaultOption,
				hideNoChangeOption: Boolean(!newPreferenceWidget.noChangeLabel)
			}
		},
		extendedPreferences: newPreferenceWidget.extendedPreferences
			? newPreferenceWidget.extendedPreferences.map(extendedPreference => ({
				keyLabel: extendedPreference.keyLabel,
				statementGroupId: extendedPreference.statementGroupId,
				channelId: extendedPreference.channelId,
				fieldTypeId: extendedPreference.fieldTypeId,
				keyId: extendedPreference.keyId,
				hideFromUi: extendedPreference.hideFromUi,
				isEditable: extendedPreference.isEditable,
				hasDefaultValue: extendedPreference.hasDefaultValue,
				defaultFreeTextValue: extendedPreference.defaultFreeTextValue,
				defaultSingleSelectValue: extendedPreference.defaultSingleSelectValue,
				dateCreated: extendedPreference.dateCreated || new Date()
			}))
			: [],
		statementGroups: newPreferenceWidget.statementGroups.map(statementGroup => ({
			statementGroupId: statementGroup.statementGroupID,
			channelId: statementGroup.channelId,
			enforceDoubleOptIn: statementGroup.enforceDoubleOptIn
		})),
		externalValues: newPreferenceWidget.externalValues.map(metadata => ({
			externalMetaDataKeyId: metadata.metaDataKeyId,
			externalFieldValue: metadata.metaDataValue
		})),
		customPrivacyPolicies: newPreferenceWidget.customPrivacyPolicies
			?	newPreferenceWidget.customPrivacyPolicies.map(privacyPolicy => ({
				languageId: privacyPolicy.languageId,
				privacyPolicyId: privacyPolicy.privacyPolicyId,
				privacyPolicyVersionId: privacyPolicy.privacyPolicyVersionId === 'Always Use Latest Version' ? null : privacyPolicy.privacyPolicyVersionId
			}))
			: []
	}

	prefWidget.statementGroups.forEach((statementGroup, index) => {
		statementGroup.displayOrder = index + 1
	})
	return configApi.post('api/PreferenceWidgets/Create', prefWidget)
}

export const updatePreferenceWidget = preferenceWidget => {
	const CHECKBOX_APPEARANCE = 1
	const RADIO_APPEARANCE = 2
	if (preferenceWidget.optionAppearance === CHECKBOX_APPEARANCE) {
		preferenceWidget.noChangeLabel = null
		preferenceWidget.optInLabel = null
		preferenceWidget.optOutLabel = null
		preferenceWidget.labelLocation = false
		preferenceWidget.optionOrder = false
		preferenceWidget.defaultOption = null
	} else if (preferenceWidget.optionAppearance === RADIO_APPEARANCE) {
		preferenceWidget.noChangeLabel = null
	}

	if (preferenceWidget.headerId === null) {
		delete preferenceWidget.headerId
	}
	if (preferenceWidget.footerId === null) {
		delete preferenceWidget.footerId
	}

	const prefWidget = {
		prefWidgetId: preferenceWidget.preferenceWidgetId,
		prefWidgetName: preferenceWidget.preferenceWidgetName,
		prefWidgetDescription: preferenceWidget.preferenceWidgetDescription,
		brandId: preferenceWidget.brandId,
		allowedDomains: preferenceWidget.allowedDomains ? preferenceWidget.allowedDomains.join(',') : null,
		confirmationData: {
			confirmationPageId: preferenceWidget.confirmationPageId,
			confirmationEmailId: preferenceWidget.confirmationEmailId
		},
		appearanceData: {
			headerId: preferenceWidget.headerId,
			footerId: preferenceWidget.footerId,
			optionsData: {
				optInLabel: preferenceWidget.optInLabel,
				optOutLabel: preferenceWidget.optOutLabel,
				noChangeLabel: preferenceWidget.noChangeLabel,
				optionsLabelsPositionId: preferenceWidget.labelLocation,
				optionsOrderId: preferenceWidget.optionOrder,
				optionSelectedByDefaultId: preferenceWidget.defaultOption,
				hideNoChangeOption: Boolean(!preferenceWidget.noChangeLabel)
			}
		},
		extendedPrefs: preferenceWidget.extendedPreferences
			? preferenceWidget.extendedPreferences.map(extendedPreference => ({
				keyLabel: extendedPreference.keyLabel,
				statementGroupId: extendedPreference.statementGroupId,
				channelId: extendedPreference.channelId,
				fieldTypeId: extendedPreference.fieldTypeId,
				keyId: extendedPreference.keyId,
				hideFromUi: extendedPreference.hideFromUi,
				isEditable: extendedPreference.isEditable,
				hasDefaultValue: extendedPreference.hasDefaultValue,
				defaultFreeTextValue: extendedPreference.defaultFreeTextValue,
				defaultSingleSelectValue: extendedPreference.defaultSingleSelectValue,
				dateCreated: extendedPreference.dateCreated || new Date()
			}))
			: [],
		statementGroups: preferenceWidget.statementGroups.map(statementGroup => ({
			statementGroupId: statementGroup.statementGroupID,
			channelId: statementGroup.channelId,
			enforceDoubleOptIn: statementGroup.enforceDoubleOptIn
		})),
		externalValues: preferenceWidget.externalValues.map(metadata => ({
			externalMetaDataKeyId: metadata.metaDataKeyId,
			externalFieldValue: metadata.metaDataValue
		})),
		customPrivacyPolicies: preferenceWidget.customPrivacyPolicies
			?	preferenceWidget.customPrivacyPolicies.map(privacyPolicy => ({
				languageId: privacyPolicy.languageId,
				privacyPolicyId: privacyPolicy.privacyPolicyId,
				privacyPolicyVersionId: privacyPolicy.privacyPolicyVersionId === 'Always Use Latest Version' ? null : privacyPolicy.privacyPolicyVersionId
			}))
			: []
	}

	prefWidget.statementGroups.forEach((statementGroup, index) => {
		statementGroup.displayOrder = index + 1
	})
	return configApi.put('api/PreferenceWidgets/Update', prefWidget)
}

export const publishPreferenceWidget = prefWidgetId => configApi.get(`api/PreferenceWidgets/Config/${prefWidgetId}`)

export const deletePreferenceWidget = preferenceWidgetId => configApi.delete(`/api/PreferenceWidgets/${preferenceWidgetId}`)

export const updateOptionLabelTranslation = optionLabelTranslation => {
	return configApi.put(`/api/PreferenceWidgets/OptionLabelTranslations`, optionLabelTranslation)
}
